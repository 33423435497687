.breadcrumb {
  display: flex;
  margin: auto;
  padding-left: 100px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Kufam', cursive;
  list-style: none;
  background-color: rgba(3, 1, 26, 0.226);
  box-shadow: 0px 0px 19px 0px rgba(5, 5, 5, 0.5);
}
.breadcrumb-item a {
  color: rgb(210, 212, 214);
}

.breadcrumb-item.active {
  color: white;
  font-weight: 600;
}

.breadcrumb-item.active::before {
  color: rgb(210, 233, 255);
  font-weight: 400;
}
.breadcrumb-generic {
  display: flex;
  margin: auto;
  flex-direction: row;
  align-items: center;
  padding-left: 100px;
  margin-top: 10px;
  text-transform: uppercase;
  font-family: 'Kufam', cursive;
  list-style: none;
  background-color: rgba(3, 1, 26, 0.226);
  box-shadow: 0px 0px 19px 0px rgba(5, 5, 5, 0.5);
}

.breadcrumb-item.active-crum {
  color: white;
  font-weight: 600;
}

.breadcrumb-item.active-crum::before {
  color: rgb(210, 233, 255);
  font-weight: 400;
}