span.page-link {
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: rgb(8, 16, 65);
  border: none;
}
.page-link {
  background-color: rgb(6, 11, 37);
  border: none;
  color: rgb(22, 101, 128);
}

.page-item.disabled .page-link {
  background-color: rgb(6, 11, 37);
  border: none;
}
