.pull-right {
  float: right !important;
}

.some-top-air {
  margin-top: 12px;
}

[hidden] {
  display: none !important;
}

.some-air {
  margin-top: 12px;
  margin-left: 20px;
}

.table {
  background: white;
}

.table thead th {
  border-top: 0px;
  border-bottom: 0px;
}