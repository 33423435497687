.modal-up {
  margin-top: 2%;
  z-index: 1000;
  width: 60rem;
  height: 50rem;
  max-width: 70%!important;
  max-height: 70%!important;
}

.title-modal {
  font-family: 'Abel', sans-serif;
  color: #45556e;
  font-size: 1.8rem;
}

.modal-header.primary {
  color: #fff;
  background-color: rgb(45, 74, 121);
  border-color: #337ab7;
}

.modal-content.primary {
  color: #fff;
  background-color: rgba(103, 124, 159);
  border-color: #337ab7;
}
