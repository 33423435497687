.tag {
  background: white;
  border-radius: 10px;
  margin-left: 5px;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  font-weight: bold;
}

.tag-customer {
  background: #346beb;
}

.tag-error {
  background: #f21313;
}

.tag-support {
  background: #ffa500;
}

.tag-verifyQA{
  background: #d6ba9c;
}

.tag-feature {
  background: #3dd413;
}

.tag-design {
  background: #dd15b2;
}

.tag-development {
  background: #13d4ce;
}

.tag-production {
  background: #1d12e3;
}

.tag-urgent {
  background: #d111ce;
}

.tag-attention {
  background: #ffe600;
}

.tag-default {
  background: grey;
}

.tag-suggestion{
  background: #1c867c;
}

.issueTitle {
  font-size: medium;
  color: #858796;
  font-weight: bold;
  display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    max-width: 100%; 
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
}